import {
  UPDATE_QUERY,
  UPDATE_ZIPCODE,
  PRICING_DATA_FETCH_SUCCESS,
  UPDATE_LOADING,
  UPDATE_SEARCH_ERROR,
  UPDATE_SEARCH,
} from "./types"

const GENERAL_PRICING_ERROR = "GENERAL_PRICING_ERROR"
const ZIPCODE_ERROR = "ZIPCODE_ERROR"
const NDC_ERROR = "NDC_ERROR"

export const updateQuery = val => ({
  type: UPDATE_QUERY,
  payload: val
})

export const updateZipcode = val => ({
  type: UPDATE_ZIPCODE,
  payload: val
})

export const updateSearch = val => ({
  type: UPDATE_SEARCH,
  payload: val
})

export const fetchPricingData = (body, search_url) => {
  return dispatch => {
    dispatch(isLoading(true))
    fetch(process.env.REACT_APP_SEARCH_URL, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer dOsHkFMHTeo99GWqY0mY"
      },
      method: "post",
      body: JSON.stringify(body)
    })
      .then(response => {
        if (!response.ok) {
          dispatch(
            setPricingError(GENERAL_PRICING_ERROR, body.pricingRequest.drugInfo.name)
          )
          dispatch(isLoading(false))
        }
        return response.json()
      })
      .then(data => {
        // Is there a 401?
        if (data.status === 401) {
          dispatch(setPricingError(ZIPCODE_ERROR, data.message))
          dispatch(pricingDataFetchSuccess())
          dispatch(isLoading(false))
          return
        }
        // Check if the price is right, Bob
        let invalid_types = ["$0", "-"]
        if (
          data.pricingReturn.pharmacyPrices[0] === undefined ||
          invalid_types.includes(data.pricingReturn.pharmacyPrices[0].price)
        ) {
          dispatch(setPricingError(GENERAL_PRICING_ERROR, body.pricingRequest.drugInfo.name))
          // Set drug price info to empty
          dispatch(pricingDataFetchSuccess())
          dispatch(isLoading(false))
        } else {
          dispatch(pricingDataFetchSuccess(data.pricingReturn))
          dispatch(isLoading(false))
        }
      })
    //.catch(err => console.error(err))
  }
}

export const pricingDataFetchSuccess = (val = null) => ({
  type: PRICING_DATA_FETCH_SUCCESS,
  payload: val
})

export const isLoading = val => ({
  type: UPDATE_LOADING,
  payload: val
})

export const setPricingError = (type = "", val) => {
  let err
  return dispatch => {
    switch (type) {
      case GENERAL_PRICING_ERROR:
        err = `We are currently updating pricing for "${val}".
          Please visit your local pharmacy for current discount pricing.`
        dispatch(updatePricingError(err))
        break
      case ZIPCODE_ERROR:
        dispatch(updatePricingError(val))
        break
      case NDC_ERROR:
        err = `NDC ${val} is either not correct or there were no results.`
        dispatch(updatePricingError(err))
        break
      default:
        dispatch(updatePricingError(""))
    }
  }
}

export const updatePricingError = val => ({
  type: UPDATE_SEARCH_ERROR,
  payload: val
})