import React from "react"
import Loader from "react-loader-spinner"
//import { ReactComponent as Animation } from "../loader.svg"

const Spinner = props => {
  return (
    <div className="spinner">
      <Loader type="ThreeDots" color="#F69E1D" height="130" width="130" />
    </div>
  )
}

export default Spinner
