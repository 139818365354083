import "react-app-polyfill/ie9"
import 'react-app-polyfill/stable';
import "core-js/features/array/includes"
import React from "react"
import ReactDOM from "react-dom"
import { Provider } from "react-redux"
import store from "./store"
import App from "./App"
import { getSlug } from "./helpers/slugHelpers"
import "./css/index.css"

import * as Sentry from '@sentry/browser';
if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: "https://79bcc32830bb48a18a32c99946e50286@sentry.io/1355366"
  });
}


// Grab the variables passed in
let drugname = ""
let zipcode = ""
let accordion = false

if (window.xprops !== undefined) {
  if (window.xprops.drugname !== undefined) {
    drugname = window.xprops.drugname
  }

  if (window.xprops.zipcode !== undefined) {
    zipcode = window.xprops.zipcode
  }

  if (window.xprops.accordion !== undefined && window.xprops.accordion === true) {
    accordion = true
  }

} else if (document.location.toString().indexOf("?") !== -1) {
  const query = document.location
    .toString()
    // get the query string
    .replace(/^.*?\?/, "")
    .replace(/#.*$/, "")
    .split("&")

  // TODO: Clean this hacky shit up
  for (let i = 0, l = query.length; i < l; i++) {
    let params = decodeURIComponent(query[i]).split("=")
    if (params[0] === "drugname") {
      drugname = params[1]
    } else if (params[0] === "zipcode") {
      zipcode = params[1]
    } else if (params[0] === "accordion") {
      accordion = params[1]
    } else if (params[2] === "drugname") {
      drugname = params[3]
    } else if (params[2] === "zipcode") {
      zipcode = params[3]
    } else if (params[2] === "accordion") {
      accordion = params[3]
    } else if (params[4] === "drugname") {
      drugname = params[5]
    } else if (params[4] === "zipcode") {
      zipcode = params[5]
    } else if (params[4] === "accordion") {
      accordion = params[5]
    }
  }
}

// Slug stuff
let slug = getSlug(window.location.pathname.substring(1))

if (slug !== "") {
  drugname = slug
}

ReactDOM.render(
  <Provider store={store}>
    <App drugname={drugname} zipcode={zipcode} accordion={accordion} />
  </Provider>,
  document.getElementById("root")
)
