import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons"
import { toTitleCase } from "../helpers/stringHelpers"
import "../css/PharmacyRow.css"

const PharmacyRow = props => {

  let pharmacy = toTitleCase(props.pharmacy)
  let address1 = toTitleCase(props.address1)
  let address2 = toTitleCase(props.address2)
  let city = toTitleCase(props.city)
  let state = props.state
  let zip = toTitleCase(props.zip)
  let price = props.price
  let pharamacy_url = `${address1}, ${address2 !== "" ? address2 : ""} ${city}, ${state} ${zip}`
  let address_class = props.accordion
    ? "portal-pharmacy-address"
    : "portal-pharmacy-address default"
  let map_class = props.accordion ? "map-icon" : "map-icon default"

  return (
    <tr>
      <td className="pharmacy">
        {!props.accordion && pharmacy}
        <span className={address_class}>
          <div className={map_class}>
            <FontAwesomeIcon icon={faMapMarkerAlt} />
          </div>
          <a target="blank" href={`https://www.google.com/maps/place/${pharamacy_url}`}>
            {address1}, {address2 !== "" ? address2 : ""}
            <span className="address">
              {city}, {state} {zip}
            </span>
          </a>
        </span>
      </td>
      <td className="price text-center align-middle">{price}</td>
    </tr>
  )
}

export default PharmacyRow
