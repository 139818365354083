import React from "react"
import ErrorMessage from "./ErrorMessage"
import TemporaryErrorMessage from "./TemporaryMessage"
import "../css/Search.css"

const SearchForm = ({ err, children,  temporary_error }) => {
  return (
    <div className="row header-wrapper">
      <div className="col-sm-12">
        <h2 className="portal-color">
          Search Prescription Savings
        </h2>
	  	  <p>
	  		  <strong>
            Enter in the name of your medication and zip code to find pricing near you.
          </strong>
          Medication prices vary by pharmacy and prescription and are subject to 
          change over time. Ask your pharmacist for the actual discounted price.
	      </p>
        { err && <ErrorMessage message={err} /> }
        { temporary_error && <TemporaryErrorMessage /> }
        <div className="drug-search-form-wrapper">
          <form className="form-inline" id="search-form">
            { children }
          </form>
        </div>
      </div>
      <div className="col-sm-12">
        <div className="disclaimer">
        </div>
      </div>
    </div>
  )
}

export default SearchForm
