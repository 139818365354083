// Styles
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap/dist/js/bootstrap.bundle.min"
import "./css/App.css"

// Components
import React from "react"
import { connect } from "react-redux"
import ErrorBoundary from "./components/ErrorBoundary"
import Search from "./components/Search"
import Spinner from "./components/Spinner"
import Results from "./components/Results"
import { Helmet } from "react-helmet"

import {
  updateQuery,
  updateZipcode,
} from "./actions/searchActions"
import { fetchSearchChoices } from "./actions/autoCompleteActions"

class App extends React.Component {
  constructor(props) {
    super(props)
    if (this.props.drugname !== "" && this.props.drugname !== undefined) {
      this.props.updateQuery(this.props.drugname)
    }

    if (this.props.zipcode !== "" && this.props.zipcode !== undefined) {
      this.props.updateZipcode(this.props.zipcode)
    }
  }

  render() {
    let accordion = this.props.accordion
    let analytics = process.env.NODE_ENV === "production" ? (
      <>
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=UA-88786262-19"
        />
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'UA-88786262-19');
          `}
        </script>
      </>
    ) : (
      ""
    )
    return (
      <ErrorBoundary>
        <Helmet>{analytics}</Helmet>
        <div className="container-fluid">
          <Search
            drugname={this.props.drugname}
            zipcode={this.props.zipcode}
          />
          {this.props.loading ? <Spinner /> : ""}
          {this.props.pricingReturn !== null &&
          !this.props.search_error &&
          !this.props.loading ? (
            <Results accordion={accordion} />
          ) : (
            ""
          )}
        </div>
      </ErrorBoundary>
    )
  }
}

const mapStateToProps = state => ({
  loading: state.loading,
  pricingReturn: state.pricingReturn,
  search_error: state.search_error
})

export default connect(
  mapStateToProps,
  {
    updateQuery,
    updateZipcode,
    fetchSearchChoices,
  }
)(App)
