import React from "react"
import { connect } from "react-redux"
import AutoComplete from "./AutoComplete"
import ZipInput from "./ZipInput"
import SearchForm from "./SearchForm"
import {
  fetchPricingData,
  updateQuery,
  updatePricingError,
} from "../actions/searchActions"
import { getZipCodeError } from "../helpers/searchHelpers"

class Search extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      emptySearch: true,
      firstSearch: true,
      temporary_error: false,
      previous_zipcode: this.props.zipcode,
      previous_drugname: this.props.query
    }
    
    // if drugname is passed, trigger the search
    if (this.props.drugname !== "" && this.props.drugname !== undefined) {
      if (!this.state.temporary_error) {
        this.getData(true)
      }
    }
  }

  componentDidUpdate() {
    if (this.props.search_error && !this.state.firstSearch) {
      this.setState({ firstSearch: true })
    }

    
  }

  render() {
    let isDisabled = this.state.temporary_error ? true : false
    return (
      <SearchForm err={this.props.search_error} temporary_error={this.state.temporary_error}>
        <AutoComplete />
        <ZipInput placeholder="Zip Code" />
        
        <button
          type="button"
          className="btn btn-success mb-2"
          id="result-btn"
          disabled={isDisabled}
          onClick={() => this.getData(false)}
        >
          Search Pricing
        </button>
        
      </SearchForm>
    )
  }

  searchParamIsNotValid = () => {
    if (this.props.query !== "") {
      if (!this.props.search_choices.includes(this.props.query)) {
        let err = `We couldn't find "${
          this.props.query
        }". Please select your medication from the dropdown list.`
        this.props.updatePricingError(err)
        return true
      }
    } else if (this.props.query === "") {
      this.props.updatePricingError("Please enter a medication")
      return true
    }
  }

  getData = (passed_var = false, reqBody = "") => {
    this.props.updatePricingError(false)
    if (!passed_var) {
      if (this.searchParamIsNotValid()) {
        return false
      }
    }

    let zipErr = getZipCodeError(this.props.zipcode)
    if (!zipErr) {
      // Remove any errors
      this.props.updatePricingError(false)
      // update the search if just the zip changed
      if (!this.state.firstSearch && this.state.previous_zipcode !== this.props.zipcode) {
        // If drugname has not changed, only update zip
        if (this.state.previous_drugname === this.props.query) {
          this.updateSearch("zip", this.props.zipcode)
          return false
        }
      }
    } else {
      // update the error
      this.props.updatePricingError(zipErr)
      return false
    }

    this.setState({ firstSearch: false, emptySearch: false, previous_zipcode: this.props.zipcode, previous_drugname: this.props.query })
    // The request needs a body
    if (reqBody === "" || reqBody === undefined) {
      reqBody = {
        pricingRequest: {
          location: {
            zipCode: this.props.zipcode
          },
          drugInfo: {
            name: this.props.query,
            form: "",
            strength: "",
            quantity: ""
          },
          type: {
            section: "name"
          }
        }
      }
    }
    // Call function to get data
    this.props.fetchPricingData(reqBody, this.props.search_url)
  }

  /**
   * Forms an update request object based on what is being updated
   */
  updateSearch = (section, update) => {
    let quantity = ""
    let regex = this.props.pricingReturn.displayItems.displayQuantity.match(
      /[+-]?\d+(\.\d+)?/g
    )
    if (regex !== null) {
      quantity = regex[0]
    }

    let search = {
      pricingRequest: {
        location: {
          zipCode: this.props.pricingReturn.location.zipCode
        },
        drugInfo: {
          name: this.props.pricingReturn.displayItems.displayDrug.name,
          form: this.props.pricingReturn.displayItems.displayForm,
          strength: this.props.pricingReturn.displayItems.displayStrength,
          quantity: quantity
        },
        type: {
          section: ""
        }
      }
    }

    switch (section) {
      case "name":
        search.pricingRequest.drugInfo.name = update
        break
      case "strength":
        search.pricingRequest.drugInfo.strength = update
        break
      case "form":
        search.pricingRequest.drugInfo.form = update
        break
      case "qty":
        search.pricingRequest.drugInfo.quantity = update
        break
      case "zip":
        section = "qty" // set section to name so it get's set properly
        search.pricingRequest.location.zipCode = update
        break
      default:
        break
    }

    search.pricingRequest.type.section = section
    this.props.fetchPricingData(search, this.props.search_url)
  }
}

const mapStateToProps = state => ({
  query: state.query,
  zipcode: state.zipcode,
  search_error: state.search_error,
  search_url: state.search_url,
  search_choices: state.search_choices,
  pricingReturn: state.pricingReturn
})

export default connect(
  mapStateToProps,
  { fetchPricingData, updateQuery, updatePricingError }
)(Search)
